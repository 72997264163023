/* You can add global styles to this file, and also import other style files */


@use 'mdi';
@use 'utils';

@use "ckeditor-content";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


mat-dialog-actions {
  justify-content: flex-end;
}

.content {
  margin: 24px 16px;
}


td {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
