.expanded-details {
  overflow: hidden;
  display: flex;
}

tr.regular-row {
  td {
    border-bottom: 0;
  }
}


td.actions {
    min-width: 150px;
}

tr.expanded-details-row {
  height: 0 !important;
}

mat-icon[matRipple] {
  cursor: pointer;
}


.mat-dialog-actions {
  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }
}


@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

button.mat-primary mat-icon {
  color: white;
}

.mat-dialog-content {
  [fxLayout] {
    margin: 16px 0 0 0 !important;
  }
}

.padding-md {
  padding: 16px;
}

.padding-right-md {
  padding-right: 16px;
}

.right {
  text-align: right;
}

.mat-elevation-z1 {
  background: white;
}

.cdk-column-actions {
 text-align: right;
}


@media only screen and (min-width: 768px) {
  .search {
    position: absolute !important;
    right: 32px;
    z-index: 10;
  }

}


.new-element {
  background: white;
  display: flex;
  align-items: center;
  padding-left: 16px;

  button {
    margin-right: 16px;
  }
}

.content {
  padding: 16px;


}


.button-form {
  margin-top: 8px !important;
  .mat-ripple {
    left: -8px !important;
    right: 8px !important;
  }
}

.archived {
  opacity: 0.5;
}


p.multiline {
  white-space: normal !important;
}

.mat-checkbox-field {
  line-height: 60px;
}


mat-toolbar.mat-elevation-z4 {
  margin-bottom: 16px;
}



.ck-editor__editable_inline {
  min-height: 500px;
}

.clear {
  clear: both;
}

.slider {
  .mat-form-field-underline {
    background: none !important;
  }
}
